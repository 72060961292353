import React, {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {loginUser} from '../utils/service/login.service';
import {useAuth} from '../utils/wrapper/authContext';
import LoginTemplate from '../templates/login.template';
import '../styles/login.css';

const LoginPage: React.FC = () => {
    const [loginError, setLoginError] = useState<string | null>(null);
    const {login} = useAuth();
    const navigate = useNavigate();

    const onSubmit = async (data: { username: string; password: string }) => {
        try {
            const {access, refresh} = await loginUser(data);
            login(access, refresh);
            navigate('/home');
        } catch (error) {
            setLoginError('Неправильное имя пользователя или пароль');
        }
    };

    useEffect(() => {
        document.title = 'СК «ТЕХПРОМСЕРВИС» | Авторизация';
    }, []);

    return (
        <LoginTemplate
            onSubmit={onSubmit}
            loginError={loginError}
            errors={{}}
        />
    );
};

export default LoginPage;
